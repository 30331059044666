// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Router, Route, Private } from '@redwoodjs/router'

const Routes = () => {
  return (
    <Router>
      <Route path="/" page={RootRedirectPage} name="rootRedirect" />
      <Route path="/admin" page={AdminPage} name="admin" />
      <Route path="/forbidden" page={NotFoundPage} name="forbidden" />
      <Private unauthenticated="forbidden" role="admin">
        <Route path="/qr-links/new" page={NewQrLinkPage} name="newQrLink" />
        <Route
          path="/qr-links/{id:Int}/edit"
          page={EditQrLinkPage}
          name="editQrLink"
        />
        <Route path="/qr-links/{id:Int}" page={QrLinkPage} name="qrLink" />
        <Route path="/qr-links" page={QrLinksPage} name="qrLinks" />
      </Private>
      <Route path="/{path}" page={QrRedirectPage} name="qrRedirect" />
      <Route notfound page={NotFoundPage} name="notfound" />
    </Router>
  )
}

export default Routes
